<template>
	<div class="home-box">
		<div class="flex">
			<div class="flex3">
				<p class="title-box">
					<span class="title">公告栏</span>
					<span class="more" @click="handleNotice">
						更多
						<i class="el-icon-arrow-right"></i>
					</span>
				</p>
				<!-- <img src="@/assets/images/ban.png" alt=""> -->
				<el-carousel height="200px">
					<el-carousel-item v-for="(item,i) in noticeList" :key="i">
						<div class="notice-box" @click="handleNoticeDetail(item.id)">
							<img class="notice-bg" src="@/assets/images/banner.png" alt="">
							<p class="notice-title" style=" -webkit-box-orient: vertical;">{{item.subject}}</p>
                            <div class="notice-content" v-html="item.content"></div>
						</div>
					</el-carousel-item>
				</el-carousel>
				<div class="title-box mb0">
					<span class="title look-more">了解更多</span>
					<div class="flex">
						<div class="liaojie-more">
							<img src="@/assets/images/FAQ.png" alt="">
							<div class="btn-box">
								<p>全球FAQ</p>
								<div class="quanqiu">
									<button class="left" @click="handleFAQ('zh')">中文版</button>
									<button class="right" @click="handleFAQ('en')">英文版</button>
								</div>
							</div>
						</div>
						<div class="liaojie-more">
							<img src="@/assets/images/xiazai.png" alt="">
							<div class="btn-box">
								<p>固件下载</p>
								<div class="gujian">
									<button @click="handleDownload">点击查看</button>
								</div>
							</div>
						</div>
					</div>
					<div class="flex">
						<div class="liaojie-more">
							<img src="@/assets/images/video.png" alt="">
							<div class="btn-box">
								<p>视频分享</p>
								<div class="video">
									<button @click="handleVideo">查看更多</button>
								</div>
							</div>
						</div>
						<div class="liaojie-more">
							<img src="@/assets/images/technique.png" alt="">
							<div class="btn-box">
								<p>技术资料</p>
								<div class="technique">
									<button @click="handleTechnical">查看更多</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="flex2">
				<p class="title-box">
					<span class="title">我的通信箱</span>
					<span class="more" @click="handleMailbox('')">
						更多
						<i class="el-icon-arrow-right"></i>
					</span>
				</p>
				<ul class="email-box">
					<li v-for="(item,i) in mailBoxList" :class="'li-border-color'+i"  :key="i" @click="handleMailbox(item)">
						<p :class="item.readFlag == 0 ? 'fwb' : ''">{{item.title}}</p>
						<p>
							<img src="@/assets/images/shijian.png" alt="">
							{{$moment(item.createTimeStr).format('YYYY-MM-DD HH:mm:ss')}}
						</p>
					</li>
				</ul>
			</div>
		</div>
		<div class="flex mt-20">
			<div class="flex4 my-class">
				<p class="title-box">
					<span class="title">我的课程</span>
					<span class="more" @click="handleCourse">
						更多
						<i class="el-icon-arrow-right"></i>
					</span>
				</p>
				<img v-if="courseList.length == 0" style="width: 100%;" src="@/assets/images/zanwukecheng.png" alt="">
				<table v-if="courseList.length > 0" class="course-table" border="0" cellpadding="0" cellspacing="0">
					<thead>
						<tr class="th-bgc">
							<th align="center" width="80">NO.</th>
							<th align="left" width="200">课程编号</th>
							<th align="left">课程名称</th>
							<th align="left" width="150">培训开始</th>
							<th align="left" width="150">培训结束</th>
						</tr>
					</thead>
					<tbody>
						<tr class="tr-bgc" v-for="(item,i) in courseList" :key="i" @click="handleExam(item)">
							<td align="center">{{i+1}}</td>
							<td class="nowrap">
								<el-tooltip class="item" effect="dark" placement="top">
									<div slot="content">{{item.courseCode}}</div>
									<span class="td-span">{{item.courseCode}}</span>
								</el-tooltip>
							</td>
							<td class="nowrap">
								<div class="nowrap1">
									<el-tooltip class="item" effect="dark" placement="top">
										<div slot="content">{{item.courseName}}</div>
										<span class="td-span">{{item.courseName}}</span>
									</el-tooltip>
								</div>
							</td>
							<td>{{item.startTime}}</td>
							<td>{{item.endTime}}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="flex1">
				<div>
					<img src="@/assets/images/liguangzhongguo.png" alt="">
					<p>理光(中国)微信号</p>
				</div>
				<div>
					<img src="@/assets/images/liguangfuwu.png" alt="">
					<p>理光服务微信号</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			// 权限
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			mailBoxList: [],
			noticeList: [],
			courseList: []
		}
	},
	mounted() {
		this.getHomeList();
		this.getOnlineList();
	},
	methods: {
		// FAQ
		handleFAQ(type) {
			// 中文版
			if(type == 'zh') {
				let that = this
				that.$request.post(
					"getFaqUrl",
					false,
					{},
					function (r) {
						window.open('https://cn-tier2-sc.custhelp.com/ci/pta/login/redirect/app/answers/list/p_li/'+JSON.parse(r.data).fqa_url)
					}
				)
			}
			// 英文版
			else {
				let that = this
				that.$request.post(
					"getFaqUrl",
					false,
					{},
					function (r) {
						window.open('https://cn-tier2-en.custhelp.com/ci/pta/login/redirect/app/answers/list/p_li/'+JSON.parse(r.data).fqa_url)
					}
				)
			}

		},
		// 固件下载
		handleDownload() {
			if(!this.perms.includes('home:download')) {
				this.$message.error('您的账号没有固件下载权限，如需固件请通过通信箱向渠道支援申请或者查询已存固件');
				return
			}
			this.$alert(
				'本网站提供的技术资料，属于理光（中国）非公开的技术信息资料等，仅供理光认证工程师维修理光产品使用，非经理光（中国）许可，不得以任何形式、通过任何途径进行复制、发行或传播。',
				'',
				{
					confirmButtonText: '确定',
					callback: action => {
						let that = this;
						that.$request.post(
							"clickFirmware",
							false,
							{},
							function (r) {
								that.$request.post(
									"getInfoByCode",
									false,
									{
										code: 'firmwareDownload'
									},
									function (r) {
										window.open('https://'+encodeURIComponent(JSON.parse(r.data.val).user)+':'+encodeURIComponent(JSON.parse(r.data.val).pwd)+'@support.ricoh.com/bbv22/html/firm_e/index_staff_e.html');
									}
								)
							}
						)
					}
				}
			);

		},
		handleVideo() {
			this.$router.push({
				name: 'video'
			})
		},
		handleTechnical() {
			this.$router.push({
				name: 'technique'
			})
		},
		getHomeList() {
			let that = this
			that.$request.post(
				"getHomeList",
				true,
				{},
				function (r) {
					if (r.code == "0") {
						that.noticeList = r.data.noticeList.list;
						that.mailBoxList = r.data.dataSubjectList.list;
					} else {
						that.$message.error(r.msg)
					}
					that.loading = false
				}
			)
		},
		handleNotice() {
			this.$router.push({
				name: 'bulletin'
			})
		},
		handleNoticeDetail(id) {
			this.$router.push({
				name: 'bulletinDetail',
				query: {
					id: id
				}
			})
		},
		// 跳转通信箱
		handleMailbox(dt) {
			if(dt) {
				if(dt.status == 0) {
					this.$router.push({
						name: 'mailboxAddEdit',
						query: {
							id: dt.id
						}
					})
				}else {
					this.$router.push({
						name: 'mailboxDetail',
						query: {
							id: dt.id
						}
					})
				}
			}else {
				this.$router.push({
					name: 'mailbox'
				})
			}
		},
		// 获取列表数据
		getOnlineList() {
			let that = this;
			that.$request.post(
				"getExamCourseList",
				true,
				{
					pageNo: 1,
					pageSize: 3
				},
				function (r) {
					if (r.code == "0") {
						that.courseList = r.data.list;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleCourse() {
			this.$router.push({
				name: 'onlineExam'
			})
		},
		handleExam(dt) {
			this.$router.push({
				name: 'examLearn',
				query: {
					courseId: dt.courseId,
					id: dt.id
				}
			})
		},
	}
}
</script>
<style lang="scss" scoped>
.nowrap{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.look-more{
	margin: 20px 0 15px;
    display: inline-block;
}
.my-class{
	box-sizing: border-box;
	padding: 20px;
}
.home-box{
	box-sizing: border-box;
	padding-top: 20px;
}
.mt-20{
	margin-top: 20px;
}
.flex{
	display: flex;
	.flex1{
		flex: 1;
		background-color: #fff;
		text-align: center;
		box-sizing: border-box;
		padding: 20px;
		border-radius: 6px;
	}
	.flex2{
		flex: 2;
		box-sizing: border-box;
		padding-left: 20px;

	}
	.flex3{
		flex: 3;
		box-sizing: border-box;
		padding: 20px;
		background-color: #fff;
		border-radius: 6px;
		img{
			width: 100%;
		}
	}
	.flex4{
		flex: 6;
		background-color: #fff;
		border-radius: 6px;
	}
}
.title-box{
	margin-bottom: 15px;

	&.mb0 {
		margin-bottom: 0 !important;
	}
	.title{
		font-size: 22px;
		font-weight: bold;
		color: #333539;
	}
	.more{
		color: #117FFC;
		cursor: pointer;
		float: right;
		margin-right: 10px;
		font-size: 14px;
	}
}
.liaojie-more{
	box-sizing: border-box;
	// padding-right: 10px;
	position: relative;

	padding-top: 6px;
	&:nth-child(even) {
		padding-right: 0;
	}
	&:nth-child(odd) {
		padding-right: 10px;
	}

	img{
		width: 100%;
	}
	.btn-box{
		position: absolute;
        top: 50%;
		left: 44px;
        transform: translateY(-50%);
		p{
			font-size: 19px;
			font-weight: bold;
			color: #333539;
		}
		.quanqiu{
			margin-top: 20px;
			background-color: #fff;
			.left{
				color: #fff;
				background-color: rgba(246, 111, 62, 1);
				border-radius: 4px 0px 0px 4px;
				border: none;
				outline: none;
				cursor: pointer;
				padding: 6px 10px;
				margin-right: 2px;
			}
			.right{
				color: #fff;
				background-color: rgba(246, 111, 62, 1);
				border-radius: 0px 4px 4px 0px;
				border: none;
				outline: none;
				cursor: pointer;
				padding: 6px 10px;
			}
		}
		.gujian{
			margin-top: 20px;
			button{
				color: #fff;
				background-color:rgba(83, 187, 154, 1);
				border-radius: 4px;
				border: none;
				outline: none;
				cursor: pointer;
				padding: 6px 18px;
			}
		}
		.video {
			margin-top: 20px;
			button{
				color: #fff;
				background-color:rgba(46, 104, 166, 1);
				border-radius: 4px;
				border: none;
				outline: none;
				cursor: pointer;
				padding: 6px 18px;
			}
		}
		.technique {
			margin-top: 20px;
			button{
				color: #fff;
				background-color:rgba(243, 126, 86, 1);
				border-radius: 4px;
				border: none;
				outline: none;
				cursor: pointer;
				padding: 6px 18px;
			}
		}
	}
}
.notice-box{
	width: 100%;
	position: relative;
	cursor: pointer;
	.notice-title{
		-webkit-box-orient: vertical;
		width: 50%;
		height: 66px;
		line-height: 22px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		position: absolute;
		top: 20px;
		left: 20px;
	}
    .notice-content {
        position: absolute;
        top: 86px;
        left: 60px;
        width: 50%;
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        line-height: 19px;
        max-height: 57px
    }
}
.email-box{
	li{
		list-style: none;
		// height: 88px;
		height: 89px;
		border-radius: 6px;
		margin-bottom: 10px;
		font-size: 13px;
		box-sizing: border-box;
		padding-top: 20px;
		padding-left: 40px;
		background-color: #fff;
		cursor: pointer;
		img{
			width: 14px;
			height: 14px;
			vertical-align: -2px;
		}
		&:last-child{
			margin-bottom: 0;
		}
		p:first-child{
			color: rgba(68, 68, 68, 1);
			font-size: 14px;
			margin-bottom: 10px;
			box-sizing: border-box;
			padding-right: 15px;
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		p:last-child{
			color: rgba(102, 102, 102, 1);
		}
		&:hover{
			box-shadow: 0px 9px 19px 2px rgba(207, 207, 207, 0.33);
		}
	}
	.li-border-color0{
		border-left: 5px solid rgba(102, 114, 251, 1);
		background-color: rgba(239, 241, 255, 1);
	}
	.li-border-color1{
		border-left: 5px solid rgba(31, 153, 142, 1);
		background-color: rgba(238, 252, 250, 1);
	}
	.li-border-color2{
		border-left: 5px solid rgba(0, 103, 255, 1);
		background-color:rgba(234, 242, 254, 1);
	}
	.li-border-color3{
		border-left: 5px solid rgba(232, 82, 63, 1);
		background-color: rgba(254, 246, 245, 1);
	}
	.li-border-color4{
		border-left: 5px solid rgba(248, 184, 73, 1);
		background-color:rgba(255, 251, 246, 1);
	}
	.li-border-color5{
		border-left: 5px solid rgb(132, 192, 152);
		background-color: rgba(238, 252, 250, 1);
	}
}
.course-table{
	width: 100%;
	border-collapse: separate;
	border-spacing: 0px 10px;
	thead{
		tr{
			border-radius: 0px 0px 4px 4px;
			th{
				height: 36px;
				font-size: 15px;
				color: #444444;
			}
		}
	}
	tbody{
		tr{
			height: 70px;
			cursor: pointer;
			td{
				img{
					vertical-align: middle;
				}
			}
			.nowrap1{
				width: 90%;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
		tr:last-child{
			border-radius: 0px 0px 4px 4px;
		}
	}
	.th-bgc{
		background-color: rgba(245, 249, 255, 0.7);
		color: rgba(68, 68, 68, 1);
	}
	.tr-bgc{
		background-color: rgba(245, 249, 255, 1);
		color: rgba(102, 102, 102, 1);
	}
}

</style>
